var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ERow',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.onResize),expression:"onResize"}],attrs:{"justify":"center","no-gutters":""}},[_c('ECol',{ref:"tableContainer",staticClass:"pa-0",attrs:{"cols":"12"}},[_c('EGlobalSearch',{attrs:{"dark":_vm.$vuetify.theme.dark,"items":_vm.reportStore.items},on:{"search-results":_vm.onGlobalSearchResultsChange,"reset":_vm.onGLobalSearchResultsReset}}),_vm._v(" "),_c('v-data-table',_vm._g(_vm._b({ref:"reportTable",staticClass:"admin-data-table",attrs:{"items":_vm.filteredItems,"loading":_vm.reportStore.loading,"headers":_vm.visibleHeaders,"options":_vm.options,"sort-by":_vm._sortBy,"sort-desc":_vm._sortDesc,"server-items-length":_vm.total,"footer-props":_vm.getProp('footer-props', {
          'items-per-page-options': [50, 100, 200],
        }),"mobile-breakpoint":_vm.getProp('mobile-breakpoint', 0),"loading-text":_vm.getProp('loading-text', 'Please wait...'),"calculate-widths":_vm.getProp('calculate-widths', true),"show-select":_vm.getProp('show-select', false),"must-sort":_vm.getProp('must-sort', true),"dense":_vm.getProp('dense', true),"fixed-header":_vm.getProp('fixed-header', true),"no-data-text":_vm.getProp('no-data-text', 'No data available'),"height":_vm.tableHeight,"hide-default-footer":_vm.hideDefaultFooter},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm._sortBy=$event},"update:sort-by":function($event){_vm._sortBy=$event},"update:sortDesc":function($event){_vm._sortDesc=$event},"update:sort-desc":function($event){_vm._sortDesc=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [(_vm.showFilterFields)?_c('ReportSearchFilters',{attrs:{"filter-fields":_vm.filterFields,"headers":_vm.headers}}):_vm._e()]},proxy:true},{key:"top",fn:function(){return [_c('v-container',{ref:"headerContainer",staticClass:"py-0",attrs:{"fluid":""}},[_c('ERow',{staticClass:"py-0"},[_c('ECol',{staticClass:"py-0",attrs:{"lg":12}},[(_vm.hasHeaderLeft || _vm.hasHeaderRight)?_c('ERow',{attrs:{"justify":"between"}},[(_vm.hasHeaderLeft)?_c('ECol',{staticClass:"py-0",attrs:{"cols":"6","md":_vm.hasHeaderRight ? (_vm.$slots['actions-left'] ? 4 : 3) : 12,"sm":_vm.hasHeaderRight ? 6 : 12}},[_vm._t("actions-left")],2):_vm._e(),_vm._v(" "),(_vm.hasHeaderRight)?_c('ECol',{staticClass:"text-right py-2 w-full",attrs:{"cols":"6","md":_vm.hasHeaderLeft ? (_vm.$slots['actions-right'] ? 5 : 4) : 12,"sm":_vm.hasHeaderLeft ? 6 : 12}},[_vm._t("actions-right"),_vm._v(" "),(
                      _vm.bookmarkStore.filteredBookmarks.length > 0 &&
                      _vm.isInIgnoreRoutes
                    )?_c('ToggleBookmarkDisplay'):_vm._e(),_vm._v(" "),(_vm.isInIgnoreRoutes)?_c('BookmarkButton'):_vm._e(),_vm._v(" "),(_vm.copyToClipboard)?_c('CopyTableToClipboard',{attrs:{"headers":_vm.visibleHeaders,"items":_vm.copyableItems}}):_vm._e(),_vm._v(" "),(_vm.hideShow)?_c('ShowHide',{attrs:{"table-fields":_vm.headers}}):_vm._e()],2):_vm._e()],1):_vm._e()],1),_vm._v(" "),(
                _vm.bookmarkStore.filteredBookmarks.length > 0 && _vm.isInIgnoreRoutes
              )?_c('ECol',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('BookmarksList')],1):_vm._e()],1)],1)]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true),model:{value:(_vm.reportStore.selectedItems),callback:function ($$v) {_vm.$set(_vm.reportStore, "selectedItems", $$v)},expression:"reportStore.selectedItems"}},'v-data-table',_vm.$attrs,false),_vm.$listeners))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }