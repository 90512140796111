<template>
  <v-menu v-model="datepicker" :close-on-content-click="false" max-width="290">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="model"
        clearable
        readonly
        dense
        hide-details
        v-bind="{ ...attrs, ...textFieldAttrs }"
        v-on="{ ...on, ...textFieldListeners }"
        @click:clear="
          () => {
            model = ''
            onChange()
          }
        "
      />
    </template>
    <v-date-picker
      v-model="model"
      v-bind="datePickerAttrs"
      v-on="datePickerListeners"
      @change="
        () => {
          datepicker = false
          onChange()
        }
      "
    />
  </v-menu>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "DatePickerSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      model: "",
      datepicker: false,
      datePickerAttrs: {},
      datePickerListeners: {},
      textFieldAttrs: {},
      textFieldListeners: {},
    }
  },
  created() {
    let { datePickerAttrs, textFieldAttrs } = this.$attrs
    this.datePickerAttrs = datePickerAttrs || {}
    this.textFieldAttrs = textFieldAttrs || {}
    let { datePickerListeners, textFieldListeners } = this.$listeners
    this.datePickerListeners = datePickerListeners || {}
    this.textFieldListeners = textFieldListeners || {}
    this.model = camelizeKeys(this.$route.query)[this.fieldName]
  },
  methods: {
    onChange() {
      this.$emit("search", {
        [this.fieldName]: this.$attrs?.convertToIso
          ? this.$moment(this.model).toISOString()
          : this.model,
      })
    },
  },
}
</script>
