<template>
  <v-text-field
    v-model="model"
    dense
    hide-details
    placeholder="Search"
    class="text-search-filter"
    v-bind="$attrs"
    @keyup="handleKeyUp"
    v-on="$listeners"
  >
    <template #prepend>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon small class="grey--text mt-1" v-on="on">
            far fa-question-circle
          </v-icon>
        </template>
        You can use operators (&#60;, &#62;, &#60;&#61;, &#62;&#61;) to filter
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "TextFieldSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      model: "",
    }
  },
  mounted() {
    this.model = camelizeKeys(this.$route.query)[this.fieldName]
  },
  methods: {
    handleKeyUp() {
      this.$emit("search", { [this.fieldName]: this.model })
    },
  },
}
</script>

<style lang="scss">
.text-search-filter {
  position: sticky;
  top: 0;
  &.v-input {
    margin-top: 0 !important;
  }
  .v-input__control > .v-input__slot {
    &:after,
    &:before {
      display: none !important;
    }
  }
  .v-input__prepend-outer {
    margin-right: 0.2rem !important;
  }
}
</style>
