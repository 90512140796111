<template>
  <tr>
    <td v-for="header in visibleHeaders" :key="header.value">
      <component
        :is="fields[header.value].component"
        v-if="fields[header.value]"
        v-bind="fields[header.value].attributes"
        :key="header.value"
        :field-name="fields[header.value].name || header.value"
        v-on="fields[header.value].listeners"
        @search="
          (search) =>
            fields[header.value].offline
              ? handleOfflineSearch(search)
              : handleSearch(search)
        "
      ></component>
    </td>
  </tr>
</template>

<script>
import { debounce } from "@evercam/shared/utils"
import TextFieldSearchFilter from "@/components/searchFilters/TextFieldSearchFilter"
import SelectSearchFilter from "@/components/searchFilters/SelectSearchFilter"
import DatePickerSearchFilter from "@/components/searchFilters/DatePickerSearchFilter"
import DatePickerRangeSearchFilter from "@/components/searchFilters/DatePickerRangeSearchFilter"
import SelectDatePickerRangeSearchFilter from "@/components/searchFilters/SelectDatePickerRangeSearchFilter"
import CountFieldSearchFilter from "@/components/searchFilters/CountFieldSearchFilter"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  components: {
    TextFieldSearchFilter,
    SelectSearchFilter,
    DatePickerSearchFilter,
    DatePickerRangeSearchFilter,
    SelectDatePickerRangeSearchFilter,
    CountFieldSearchFilter,
  },
  props: {
    filterFields: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchParams: {},
    }
  },
  computed: {
    ...mapStores(useReportStore),
    fields() {
      return this.filterFields
    },
    visibleHeaders() {
      const IsShowSelectColumn = Object.values(
        this.reportStore.selectedHeaders[0]
      )?.length
      const visibleHeaders = this.headers.filter((s) =>
        this.reportStore.selectedHeaders.includes(s)
      )

      return IsShowSelectColumn === 0 ? [{}, ...visibleHeaders] : visibleHeaders
    },
  },

  created() {
    this.reportStore.offlineSearchParams = {}
  },
  methods: {
    handleSearch(change) {
      this.searchParams = {
        ...this.searchParams,
        ...change,
      }
      this.fireFilter(this)
    },
    handleOfflineSearch: debounce(function (search) {
      this.reportStore.offlineSearchParams = {
        ...this.reportStore.offlineSearchParams,
        ...search,
      }
    }, 200),
    fireFilter: debounce(function (self) {
      self.reportStore.searchFilters = self.searchParams
    }, 500),
  },
}
</script>
