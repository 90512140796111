<template>
  <ERow v-resize-observer="onResize" justify="center" no-gutters>
    <ECol ref="tableContainer" cols="12" class="pa-0">
      <!-- Global search -->
      <EGlobalSearch
        :dark="$vuetify.theme.dark"
        :items="reportStore.items"
        @search-results="onGlobalSearchResultsChange"
        @reset="onGLobalSearchResultsReset"
      />

      <v-data-table
        ref="reportTable"
        v-model="reportStore.selectedItems"
        :items="filteredItems"
        :loading="reportStore.loading"
        :headers="visibleHeaders"
        :options.sync="options"
        :sort-by.sync="_sortBy"
        :sort-desc.sync="_sortDesc"
        :server-items-length="total"
        class="admin-data-table"
        :footer-props="
          getProp('footer-props', {
            'items-per-page-options': [50, 100, 200],
          })
        "
        :mobile-breakpoint="getProp('mobile-breakpoint', 0)"
        :loading-text="getProp('loading-text', 'Please wait...')"
        :calculate-widths="getProp('calculate-widths', true)"
        :show-select="getProp('show-select', false)"
        :must-sort="getProp('must-sort', true)"
        :dense="getProp('dense', true)"
        :fixed-header="getProp('fixed-header', true)"
        :no-data-text="getProp('no-data-text', 'No data available')"
        :height="tableHeight"
        v-bind="$attrs"
        :hide-default-footer="hideDefaultFooter"
        v-on="$listeners"
      >
        <!-- Search filters -->
        <template #body.prepend>
          <ReportSearchFilters
            v-if="showFilterFields"
            :filter-fields="filterFields"
            :headers="headers"
          />
        </template>
        <!-- Header -->
        <template #top>
          <v-container ref="headerContainer" fluid class="py-0">
            <ERow class="py-0">
              <ECol :lg="12" class="py-0">
                <ERow v-if="hasHeaderLeft || hasHeaderRight" justify="between">
                  <ECol
                    v-if="hasHeaderLeft"
                    cols="6"
                    :md="hasHeaderRight ? ($slots['actions-left'] ? 4 : 3) : 12"
                    :sm="hasHeaderRight ? 6 : 12"
                    class="py-0"
                  >
                    <!-- @slot: Left side header Actions -->
                    <slot name="actions-left"> </slot>
                  </ECol>

                  <!-- <v-spacer></v-spacer> -->

                  <ECol
                    v-if="hasHeaderRight"
                    cols="6"
                    :md="hasHeaderLeft ? ($slots['actions-right'] ? 5 : 4) : 12"
                    :sm="hasHeaderLeft ? 6 : 12"
                    class="text-right py-2 w-full"
                  >
                    <!-- @slot: Right side header Actions -->
                    <slot name="actions-right"></slot>
                    <!-- Bookmark Button -->
                    <ToggleBookmarkDisplay
                      v-if="
                        bookmarkStore.filteredBookmarks.length > 0 &&
                        isInIgnoreRoutes
                      "
                    />
                    <BookmarkButton v-if="isInIgnoreRoutes" />
                    <!-- Copy report to Clipboard -->
                    <CopyTableToClipboard
                      v-if="copyToClipboard"
                      :headers="visibleHeaders"
                      :items="copyableItems"
                    />
                    <!-- Hide / show button -->
                    <ShowHide v-if="hideShow" :table-fields="headers" />
                  </ECol>
                </ERow>
              </ECol>
              <ECol
                v-if="
                  bookmarkStore.filteredBookmarks.length > 0 && isInIgnoreRoutes
                "
                cols="12"
                class="py-0"
              >
                <!-- @slot: Left side header Actions -->
                <BookmarksList />
              </ECol>
            </ERow>
          </v-container>
        </template>

        <!-- Forward Vuetify v-data-table scoped slots -->
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import { decamelize, decamelizeKeys, camelizeKeys } from "humps"
import ResizeTable from "@/mixins/resizeTable"
import ShowHide from "@/components/ShowHide"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import BookmarkButton from "@/components/bookmarks/BookmarkButton"
import ReportSearchFilters from "@/components/ReportSearchFilters"
const equal = require("deep-equal")
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import axios from "@evercam/shared/api/client/axios"
import BookmarksList from "@/components/bookmarks/BookmarksList"
import ToggleBookmarkDisplay from "@/components/bookmarks/ToggleBookmarkDisplay"
import { useBookmarksStore } from "@/stores/bookmarks"

export default {
  name: "ReportTable",
  components: {
    ShowHide,
    CopyTableToClipboard,
    ReportSearchFilters,
    BookmarkButton,
    BookmarksList,
    ToggleBookmarkDisplay,
  },
  mixins: [ResizeTable],
  props: {
    /**
     * Vuetify v-data-table headers prop
     */
    headers: {
      type: Array,
      default: () => [],
    },
    /**
     * Top right HideShow button visibility
     */
    hideShow: {
      type: Boolean,
      default: true,
    },
    /**
     * Top right CopyTableToClipboard button visibility
     */
    copyToClipboard: {
      type: Boolean,
      default: true,
    },
    /**
     * Vuetify v-data-table sort-by prop
     */
    sortBy: {
      type: String,
      default: "created_at",
    },
    /**
     * Vuetify v-data-table sort-desc prop
     */
    sortDesc: {
      type: Boolean,
      default: true,
    },
    /**
     * Name of the report (e.g.: "cameras")
     */
    name: {
      type: String,
      default: "items",
    },
    /**
     * Request handler function used in items search (e.g.: AdminApi.cameras.getSnapshotExtractions)
     */
    provider: {
      type: Function,
      default: async () => {
        console.error(
          "<ReportTable /> expects a 'provider' function for handling API calls"
        )

        return {
          total: 0,
          data: [],
        }
      },
    },
    /**
     * The response object property that contains the items array
     */
    dataField: {
      type: String,
      default: "items",
    },
    /**
     * Optional result items processor function
     * @function Array<Item> => Array<CustomItem>
     */
    processItems: {
      type: Function,
      default: (items) => items,
    },
    /**
     * The page limit property that specify the number of items displayed per page
     */
    pageLimitField: {
      type: String,
      default: "limit",
    },
    /**
     * The unique Id property that avoid 'duplicate key' error in console when there's no unique key in item object,
     * by setting item-key to 'subId' and unique-id to true
     */
    uniqueId: {
      type: Boolean,
      default: false,
    },
    filterFields: {
      type: Object,
      default: () => ({}),
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    globalSearch: {
      type: Boolean,
      default: false,
    },
    forceSnakeCase: {
      type: Boolean,
      default: true,
    },
    forceUrlUpdate: {
      type: Boolean,
      default: false,
    },
    separateSortParams: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      total: 0,
      options: {},
      searchParams: {},
      _sortBy: null,
      _sortDesc: null,
      unmounted: false,
      oldQuery: {},
      tableHeight: null,
      defaultParams: {},
      isGlobalSearchActive: false,
      globalSearchResults: [],
    }
  },
  computed: {
    ...mapStores(useReportStore, useBookmarksStore),
    isInIgnoreRoutes() {
      return (
        this.$route.path !== "/offline-cameras" &&
        this.$route.path !== "/site-visit-cameras" &&
        this.$route.path !== "/offline-cameras" &&
        this.$route.path !== "/site-view" &&
        this.$route.path !== "/ingest" &&
        this.$route.path !== "/360-view" &&
        this.$route.path !== "/map-view" &&
        this.$route.path !== "/emailing/suppressions"
      )
    },
    visibleHeaders() {
      return this.headers.filter((s) =>
        this.reportStore.selectedHeaders.includes(s)
      )
    },
    hasHeaderLeft() {
      return this.$slots["actions-left"]
    },
    hasHeaderRight() {
      return (
        this.$slots["actions-right"] || this.hideShow || this.copyToClipboard
      )
    },
    copyableItems() {
      let copyableItems = this.$refs.reportTable?.internalCurrentItems

      return copyableItems ? copyableItems : this.reportStore.items
    },
    filteredItems() {
      if (this.isGlobalSearchActive) {
        return this.globalSearchResults.map((match) => {
          return {
            ...this.reportStore.items[match.index],
            ...match,
          }
        })
      }

      const offlineSearchParams = Object.entries(
        this.reportStore.offlineSearchParams
      ).filter(([, v]) => v)

      if (!offlineSearchParams.length) {
        return this.reportStore.items
      }

      const defaultFilter = (item, fieldName, search) =>
        `${item[fieldName]}`.toLowerCase()?.includes(search.toLowerCase())

      return this.reportStore.items.filter((item) => {
        let isMatched = true
        for (let i = 0; i < offlineSearchParams.length; i++) {
          const [fieldName, search] = offlineSearchParams[i]
          const applyFilter =
            this.filterFields[fieldName]?.customFilter || defaultFilter
          isMatched &&= applyFilter(item, fieldName, search)
        }

        return isMatched
      })
    },
    showFilterFields() {
      return Object.values(this.filterFields)?.length > 0
    },
    formattedRouteQuery() {
      return this.formatRouteQuery(this.$route.query)
    },
  },
  watch: {
    options: {
      async handler() {
        this.$root.$emit("table-options-updated")
        if (!this.getProp("custom-sort", false)) {
          await this.getDataFromApi()
        }
      },
      deep: true,
    },
    $route: {
      handler: function (to, _) {
        const { limit, page, sort, ...rest } = to.query
        if (this.unmounted) {
          return
        }
        this.reportStore.searchFilters = rest
        this.bookmarkStore.isPathBookmarked =
          !!this.bookmarkStore.bookmarks.find(
            (item) => item.url === to.pathname
          )
        this.bookmarkStore.filteredBookmarks =
          this.bookmarkStore.bookmarks.filter(
            (bookmark) => bookmark.route === to.path
          )
      },
      immediate: true,
      deep: true,
    },
    "reportStore.searchFilters": {
      async handler(value) {
        await this.handleFilter(value)
        this.bookmarkStore.isPathBookmarked =
          !!this.bookmarkStore.bookmarks.find(
            (item) => item.url === this.bookmarkStore.getPathname()
          )
        this.bookmarkStore.loadBookmarks()
      },
    },
    "reportStore.items": {
      async handler(value) {
        // When items are deleted, we want to refresh the table
        // Don't refresh when we are in the last page
        if (this.hideDefaultFooter) {
          return
        }
        let totalPages = Math.ceil(this.total / this.options.itemsPerPage)
        if (
          (this.total > 0 && value.length === 0) ||
          (this.options.page < totalPages &&
            value.length !== this.options.itemsPerPage &&
            JSON.stringify(this.reportStore.items) !=
              JSON.stringify(this.filteredItems))
        ) {
          await this.getDataFromApi()
        }
      },
    },
  },
  created() {
    this.reportStore.selectedItems = []
    let selectedHeaders = Object.values(this.headers).filter((h) => {
      return h.visible !== false
    })
    if (this.getProp("show-select", false) !== false) {
      selectedHeaders = [{}, ...selectedHeaders]
    }
    this.reportStore.selectedHeaders = selectedHeaders
    this._sortBy = this.sortBy
    this._sortDesc = this.sortDesc
  },
  beforeDestroy() {
    axios.cancelRequests()
    this.reportStore.initialSearchParams = {}
    axios.cancelRequests()
  },
  mounted() {
    this.$setTimeout(this.fixSubHeadersPosition, 1000)
    this.$root.$on("reset-search-params", () => {
      if (Object.keys(this.formattedRouteQuery).length < 1) {
        this.searchParams = {}
        this.reportStore.searchFilters = {}
      } else {
        this.reportStore.searchFilters = this.formattedRouteQuery
      }
      this.$root.$emit("update-filter-fields")
    })
    this.$root.$on("refresh-report-table", () => {
      this.getDataFromApi()
    })
  },
  destroyed() {
    this.unmounted = true
  },
  methods: {
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    async handleFilter(data) {
      if (this.unmounted) {
        return
      }
      this.searchParams = {
        ...this.reportStore.initialSearchParams,
        ...this.reportStore.searchFilters,
        ...data,
      }
      await this.getDataFromApi()
    },
    async getDataFromApi() {
      try {
        this.reportStore.loading = true
        axios.cancelRequests()
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = sortBy[0] || this.sortBy || "created_at"

        if (this.forceSnakeCase) {
          sortBy = decamelize(sortBy)
        }

        let sortParams = {}

        if (this.separateSortParams) {
          sortParams = {
            sortBy: sortBy,
            sortDirection: this.whichSort(sortDesc[0]),
          }
        } else {
          sortParams = {
            sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          }
        }

        this.searchParams = {
          ...this.reportStore.initialSearchParams,
          ...this.searchParams,
          ...sortParams,
          [this.pageLimitField]: itemsPerPage,
          page: page,
        }

        if (
          !equal(this.oldQuery, this.formattedRouteQuery) &&
          !equal(this.searchParams, this.formattedRouteQuery) &&
          Object.keys(this.formattedRouteQuery).length !== 0
        ) {
          this.searchParams = {
            ...this.searchParams,
            ...this.formattedRouteQuery,
          }
          this.oldQuery = this.formattedRouteQuery

          if (this.formattedRouteQuery.sort) {
            this.options.sortBy =
              [this.formattedRouteQuery.sort?.split("|")[0]] || sortBy
          }
          this.options.page = this.formattedRouteQuery.page || page
          this.options.itemsPerPage =
            this.formattedRouteQuery.perPage || itemsPerPage
        } else {
          this.defaultParams = {
            ...sortParams,
            [this.pageLimitField]: itemsPerPage,
            page: page,
          }

          const queryString = this.toQueryString(
            this.forceSnakeCase
              ? decamelizeKeys(this.searchParams)
              : this.searchParams
          )

          const defaultParamsQueryString = this.toQueryString(
            this.defaultParams
          )
          this.updatePageURL(
            queryString,
            defaultParamsQueryString,
            this.forceUrlUpdate
          )
        }
        const requestCancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(requestCancelToken)

        const response = await this.provider({
          cancelToken: requestCancelToken?.token,
          params: this.searchParams,
        })
        this.total = response.total
        this.reportStore.items = this.processItems(response[this.dataField])
        if (this.uniqueId) {
          this.reportStore.items.forEach((item, i) => {
            item.subId = i + 1
          })
        }
      } catch (error) {
        this.$notifications.error({
          text: `An error occurred fetching ${this.name}`,
          error,
        })
      } finally {
        this.reportStore.loading = false
        this.$emit("change", this.reportStore.items)
      }
    },
    whichSort(type) {
      return type ? "desc" : "asc"
    },
    fixSubHeadersPosition() {
      if (this.showFilterFields) {
        const cellHeightHeader = this.$refs.tableContainer?.$el
          ?.querySelector("table thead tr th")
          ?.getBoundingClientRect()?.height
        const subHeaders = Array.from(
          this.$refs.tableContainer?.$el?.querySelectorAll(
            "table tbody tr:first-of-type td"
          )
        )
        subHeaders.forEach((td) => {
          td.style.zIndex = "10"
          td.style.position = "sticky"
          td.style.background = "white"
          td.style.top = `${cellHeightHeader}px`
        })
      }
    },
    formatRouteQuery(query = {}) {
      return Object.entries(camelizeKeys(query)).reduce((acc, [key, value]) => {
        const matchingField = Object.values(this.filterFields).find(
          (field) => field.name === key
        )

        const isMultiple =
          matchingField?.attributes?.multiple ||
          this.filterFields[key]?.attributes?.multiple
        if (isMultiple && !Array.isArray(value)) {
          return { ...acc, [key]: [value] }
        }

        return { ...acc, [key]: value }
      }, {})
    },
    onGlobalSearchResultsChange(results) {
      this.globalSearchResults = results
      this.isGlobalSearchActive = true
    },
    onGLobalSearchResultsReset() {
      this.isGlobalSearchActive = false
    },
  },
}
</script>

<style>
.global-search--fixed {
  position: fixed;
}
</style>
