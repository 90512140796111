<template>
  <v-select
    v-model="selectedDateRange"
    :items="dateRanges"
    item-text="range"
    item-value="value"
    placeholder="Date Range"
    style="z-index: 12"
    hide-details
    dense
    @change="onChangeSelectDateRange"
  >
    <template #selection="{ item, attrs, on }">
      <div v-if="item.value == 3" class="mb-1" @click.stop>
        <v-menu v-model="dateRangePicker" :close-on-content-click="false">
          <template #activator="{ on: onMenu, attrs: attrsMenu }">
            <v-text-field
              :value="displayDateRange"
              readonly
              dense
              hide-details
              v-bind="attrsMenu"
              v-on="onMenu"
            />
          </template>
          <v-date-picker v-model="dateRange" range @change="dateRangePicked" />
        </v-menu>
      </div>
      <div v-else v-bind="attrs" class="pa-0 ma-0" v-on="on">
        {{ item.range }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "SelectDatePickerRangeSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      dateRanges: [
        { range: "No filter", value: 0 },
        { range: "Last 7 Days", value: 1 },
        { range: "Last 30 Days", value: 2 },
        { range: "Custom", value: 3 },
      ],
      dateRangePicker: false,
      dateRange: [],
      selectedDateRange: 0,
      fromDate: null,
      toDate: null,
      fromDateSuffix: "From",
      toDateSuffix: "To",
    }
  },
  computed: {
    displayDateRange() {
      if (!this.fromDate || !this.toDate) {
        return "(From - To)"
      }

      return this.fromDate + "-" + this.toDate
    },
  },
  mounted() {
    let { fromDateSuffix, toDateSuffix } = this.$attrs
    this.fromDateSuffix = fromDateSuffix || "From"
    this.toDateSuffix = toDateSuffix || "To"

    const query = camelizeKeys(this.$route.query)
    this.dateRange = [
      query[`${this.fieldName}${this.fromDateSuffix}`],
      query[`${this.fieldName}${this.toDateSuffix}`],
    ]
    this.selectedDateRange = parseInt(query[this.fieldName]) || 0
    this.onChangeSelectDateRange(this.selectedDateRange)
  },
  methods: {
    onChangeSelectDateRange(selectedRange) {
      if (selectedRange === 0) {
        this.fromDate = null
        this.toDate = null
      } else if (selectedRange === 1) {
        this.fromDate = this.$moment()
          .subtract({ days: 7 })
          .format("YYYY-MM-DD")
        this.toDate = this.$moment().format("YYYY-MM-DD")
      } else if (selectedRange === 2) {
        this.fromDate = this.$moment()
          .subtract({ days: 30 })
          .format("YYYY-MM-DD")
        this.toDate = this.$moment().format("YYYY-MM-DD")
      } else {
        this.dateRangePicked(this.dateRange, false)
      }
      this.dateRangePicker = false
      this.changeFilters()
    },
    dateRangePicked(range, updateParams = true) {
      this.dateRangePicker = false
      if (this.$moment(range[0]).isAfter(this.$moment(range[1]))) {
        this.fromDate = range[1]
        this.toDate = range[0]
      } else {
        this.fromDate = range[0]
        this.toDate = range[1]
      }

      if (updateParams) {
        this.changeFilters()
      }
    },
    changeFilters() {
      this.$emit("search", {
        [this.fieldName]: this.selectedDateRange,
        [`${this.fieldName}${this.fromDateSuffix}`]: this.fromDate,
        [`${this.fieldName}${this.toDateSuffix}`]: this.toDate,
      })
    },
  },
}
</script>
