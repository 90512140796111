var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:_vm.buttonClasses,attrs:{"color":"primary","small":""},on:{"click":_vm.bookmarkStore.toggleBookmarks}},'v-btn',{ ...attrs, ..._vm.$attrs },false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("\n        "+_vm._s(_vm.bookmarkStore.isPathBookmarked ? "fas" : "far")+" fa-bookmark\n      ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(`${
          _vm.bookmarkStore.isPathBookmarked
            ? "Remove from Bookmarks"
            : "Add to Bookmarks"
        }`))])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(`${
      _vm.bookmarkStore.isPathBookmarked
        ? "Remove from Bookmarks"
        : "Add to Bookmarks"
    }`))])])
}
var staticRenderFns = []

export { render, staticRenderFns }