<template>
  <v-menu v-model="datepicker" :close-on-content-click="false" max-width="290">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeDisplay"
        placeholder="Date Range"
        readonly
        dense
        hide-details
        v-bind="{ ...attrs, ...textFieldAttrs }"
        v-on="{ ...on, ...textFieldListeners }"
        @click:clear="onChange"
      />
    </template>
    <v-date-picker
      v-model="dateRange"
      range
      v-bind="datePickerAttrs"
      v-on="datePickerListeners"
      @change="
        () => {
          onChange()
          datepicker = false
        }
      "
    />
  </v-menu>
</template>

<script>
import { camelizeKeys } from "humps"

export default {
  name: "DatePickerRangeSearchFilter",
  props: {
    fieldName: {
      type: String,
      default: "",
      mandatory: true,
    },
  },
  data() {
    return {
      model: [],
      dateRange: [],
      datepicker: false,
      datePickerAttrs: {},
      datePickerListeners: {},
      textFieldAttrs: {},
      textFieldListeners: {},
    }
  },
  computed: {
    dateRangeDisplay() {
      return this.model.join(" - ")
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      handler(dates) {
        if (dates.length !== 2) {
          return
        }
        if (this.$moment(dates[0]).isAfter(this.$moment(dates[1]))) {
          this.model = [dates[1], dates[0]]
        } else {
          this.model = dates
        }
        this.onChange()
      },
    },
  },
  created() {
    let { datePickerAttrs, textFieldAttrs } = this.$attrs
    this.datePickerAttrs = datePickerAttrs || {}
    this.textFieldAttrs = textFieldAttrs || {}
    let { datePickerListeners, textFieldListeners } = this.$listeners
    this.datePickerListeners = datePickerListeners || {}
    this.textFieldListeners = textFieldListeners || {}
    this.dateRange =
      camelizeKeys(this.$route.query)[this.fieldName] ||
      this.datePickerAttrs?.value ||
      []
  },
  methods: {
    onChange() {
      this.$emit("search", {
        [this.fieldName]: this.model,
      })
    },
  },
}
</script>
