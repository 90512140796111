var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","readonly":"","dense":"","hide-details":""},on:{"click:clear":() => {
          _vm.model = ''
          _vm.onChange()
        }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-text-field',{ ...attrs, ..._vm.textFieldAttrs },false),{ ...on, ..._vm.textFieldListeners }))]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_vm._v(" "),_c('v-date-picker',_vm._g(_vm._b({on:{"change":() => {
        _vm.datepicker = false
        _vm.onChange()
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-date-picker',_vm.datePickerAttrs,false),_vm.datePickerListeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }