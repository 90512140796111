import { render, staticRenderFns } from "./ToggleBookmarkDisplay.vue?vue&type=template&id=771b3d09"
import script from "./ToggleBookmarkDisplay.vue?vue&type=script&lang=ts"
export * from "./ToggleBookmarkDisplay.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports