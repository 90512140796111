<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="{ ...attrs, ...$attrs }"
        color="primary"
        :class="buttonClasses"
        small
        v-on="on"
        @click="bookmarkStore.toggleBookmarks"
      >
        <v-icon x-small class="mr-2">
          {{ bookmarkStore.isPathBookmarked ? "fas" : "far" }} fa-bookmark
        </v-icon>
        <span>{{
          `${
            bookmarkStore.isPathBookmarked
              ? "Remove from Bookmarks"
              : "Add to Bookmarks"
          }`
        }}</span>
      </v-btn>
    </template>
    <span>{{
      `${
        bookmarkStore.isPathBookmarked
          ? "Remove from Bookmarks"
          : "Add to Bookmarks"
      }`
    }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useBookmarksStore } from "@/stores/bookmarks"

export default Vue.extend({
  name: "BookmarkButton",
  props: {
    buttonClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapStores(useBookmarksStore),
  },
})
</script>
