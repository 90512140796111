<template>
  <ERow
    v-if="
      bookmarkStore.filteredBookmarks.length > 0 &&
      bookmarkStore.isBookmarksDisplayed
    "
    class="py-0 w-fit-content"
  >
    <ECol lg="12" class="py-0 d-flex flex-wrap">
      <v-chip
        v-for="item in bookmarkStore.filteredBookmarks"
        :key="item.url"
        small
        label
        class="ma-2"
        :color="isBookmarked(item) ? 'primary' : undefined"
        :to="item.url"
        close
        exact-path
        :outlined="isBookmarked(item)"
        @click="onBookmarkItemClick"
        @click:close="bookmarkStore.removeFromBookmarks(item.url)"
      >
        {{ item.name }}
      </v-chip>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"

import { Bookmark, useBookmarksStore } from "@/stores/bookmarks"
import { useReportStore } from "@/stores/report"

export default Vue.extend({
  name: "BookmarksList",
  data() {
    return {
      isShown: true,
    }
  },
  computed: {
    ...mapStores(useBookmarksStore, useReportStore),
  },
  watch: {
    "bookmarkStore.bookmarks"() {
      this.bookmarkStore.loadBookmarks()
    },
  },
  methods: {
    isBookmarked(item: Bookmark) {
      return item.url === this.bookmarkStore.getPathname()
    },
    onBookmarkItemClick() {
      this.$root.$emit("reset-search-params")
    },
  },
})
</script>

<style scoped>
.w-fit-content {
  width: fit-content;
}
.v-icon.v-icon.v-icon--link {
  color: white !important;
}
.z-1000 {
  z-index: 1000 !important;
}
.btn-forbidden {
  font-size: 12px !important;
  text-align: start !important;
  text-transform: none !important;
  width: 100% !important;
  justify-content: start;
}

.no-hover-effect:hover,
.no-hover-effect:focus {
  background: none !important;
  color: inherit; /* Optionally, reset the text color to inherit */
}
</style>
