<template>
  <v-btn
    small
    color="primary"
    class="mx-2"
    @click="
      () =>
        (bookmarkStore.isBookmarksDisplayed =
          !bookmarkStore.isBookmarksDisplayed)
    "
  >
    <v-icon class="no-hover-effect mr-2" x-small>
      fa-regular
      {{
        bookmarkStore.isBookmarksDisplayed ? "fa-chevron-up" : "fa-chevron-down"
      }}
    </v-icon>
    <span>{{
      bookmarkStore.isBookmarksDisplayed ? "Hide bookmarks" : "Show Bookmarks"
    }}</span>
  </v-btn>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useBookmarksStore } from "@/stores/bookmarks"

export default Vue.extend({
  name: "ToggleBookmarkDisplay",

  computed: {
    ...mapStores(useBookmarksStore),
  },
})
</script>
